import { CodeBlock, CopyBlock, dracula } from 'react-code-blocks';


interface IShowCodeProps{
    code: string,
    language: string,
    showLineNumbers: boolean
}
const ShowCode: React.FC<IShowCodeProps> = (props) =>{
  return (
    <CopyBlock
      text={props.code}
      language={props.language}
      showLineNumbers={props.showLineNumbers}
      theme={dracula}
    />
  );
}

export default ShowCode