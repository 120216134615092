import axiosInstance from "../../api/api_client"
import { IPostProductRequest } from "../models/product"

const getAll = async (page: number) => {
    let response = await axiosInstance.get(`/stock?page=${page}`)
    return response
}

const getById = async (id: number) => {
    let response = await axiosInstance.get(`/stock/${id}`)
    return response
}

const store = async (data: IPostProductRequest) => {
    let response = await axiosInstance.post('/stock', data)
    return response
}


const stockRepository = {
    getAll,
    getById,
    store,
}

export default stockRepository