import { ApiConstants } from "../../../constants/api_constants"
import axiosInstance from "../../api/api_client"
import { IPostProductRequest } from "../models/product"

const getAll = async () => {
    let response = await axiosInstance.get(`/neighborhoods`)
    return response
}

const getById = async (id: number) => {
    let response = await axiosInstance.get(`/neighborhoods/${id}`)
    return response
}

const store = async (data: IPostProductRequest) => {
    await axiosInstance.get('/sanctum/csrf-cookie', { baseURL: ApiConstants.baseUrl })
    let response = await axiosInstance.post('/neighborhoods', data)
    return response
}


const bairrosRepository = {
    getAll,
    getById,
    store,
}

export default bairrosRepository