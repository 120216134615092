import { AddAPhoto, CloudUpload, RecyclingSharp } from '@mui/icons-material';
import { Box, Button, Grid, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import LoadingIndicator from '../../components/loading_indicator';
import { ApiConstants } from '../../constants/api_constants';
import productRepository from '../../shared/services/repositories/product_repository';
import { LoadingButton } from '@mui/lab';
import Image from 'mui-image';


interface IImageUploadProps {
    productId: number,
    IsToEdit?: boolean,
    imgs?: string[],
    updateImgs?: (imgs: string[]) => void,
}

const ImageUpload: React.FC<IImageUploadProps> = (props) => {
    const [selectedFile, setSelectedFile] = useState<File[]>([])
    const [filesUrl, setFilesUrls] = useState<string[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [deleting, setDeleting] = useState<boolean>(false)
    const [uploading, setUploading] = useState<boolean>(false)

    const handleFileChange = (acceptedFiles: File[]) => {
        const urls = Array.from(acceptedFiles).map(file => URL.createObjectURL(file));
        setFilesUrls(urls);
        setSelectedFile(old=>acceptedFiles);
    };


    const handleUpload = () => {

        let formData = new FormData();
        selectedFile.forEach(f => formData.append('images[]', f))
        // formData.forEach((v, k) => console.log(k, v))

        setUploading(true)

        productRepository
            .uploadImages(formData, props.productId)
            .then(response => {
                if (props.IsToEdit) {
                    if (props.updateImgs)
                        props?.updateImgs(response.data.imgs_url)
                    setSelectedFile([])
                }
            })
            .catch(error => {
                console.log(error)
            }).finally(() => {
                setUploading(false)
            })
    };

    const handleRemoveImgs = () => {
        setDeleting(true)
        productRepository
            .removeImgs(props.productId)
            .then(res => {
                if (props.updateImgs)
                    props.updateImgs([])
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setDeleting(false)
            })
    }

    const displayImgs = () => {
        if (props.imgs) {
            return (
                <Stack
                    direction={'row'}
                    spacing={1}
                    sx={{ maxHeight: '120px', maxWidth: '120px' }}
                >
                    {
                        props.imgs.map((v, i) =>
                            <Image key={i} src={ApiConstants.baseUrlFiles + v} alt={v} width={'5rem'} showLoading />
                        )}
                </Stack>

            )
        }
        return <></>
    }



    return (
        <>
            <LoadingIndicator show={loading} />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box mb={2}>
                        {props.imgs ?
                            displayImgs()
                            : <></>}
                    </Box>
                    <Dropzone onDrop={handleFileChange} multiple={true} maxFiles={3} >
                        {({ getRootProps, getInputProps }) => (

                            <Box
                                {...getRootProps()}
                                sx={{
                                    border: '2px dashed #ccc',
                                    padding: '20px',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    background: 'rgba(0,0,255,.1)'
                                }}
                            >
                                <input {...getInputProps()} />
                                {

                                    (selectedFile.length > 0)
                                        ?
                                        <Stack
                                            direction={'row'}
                                            spacing={1}
                                            sx={{ maxHeight: '120px', maxWidth: '120px' }}
                                        >
                                            {
                                                filesUrl?.map((file, index) =>
                                                    <Image key={index} src={file} alt="" width={'8rem'} showLoading  />
                                                )
                                            }
                                        </Stack>
                                        :
                                        <Box>
                                            <AddAPhoto />
                                            <p>Puxe a imagem para aqui ou click para adicionar</p>
                                        </Box>
                                }
                            </Box>
                        )}
                    </Dropzone>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction={'row'} spacing={2}>

                        <LoadingButton
                            variant="contained"
                            color="primary"
                            startIcon={<CloudUpload />}
                            onClick={handleUpload}
                            disabled={selectedFile.length <= 0}
                            loading={uploading}
                        >
                            Upload
                        </LoadingButton>
                        <LoadingButton
                            variant="contained"
                            color="error"
                            startIcon={<RecyclingSharp />}
                            onClick={handleRemoveImgs}
                            disabled={props.imgs?.length === 0}
                            hidden={!props.IsToEdit}
                            loading={deleting}
                        >
                            Remover todas
                        </LoadingButton>
                    </Stack>
                </Grid>
            </Grid>
        </>
    );
};

export default ImageUpload;
