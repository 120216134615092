import { ApiConstants } from "../../../constants/api_constants"
import axiosInstance from "../../api/api_client"
import { IPaymentMethodPost, IPaymentMethodPut, IPaymentMethodPutActive } from "../models/payment-method"

const getAll = async () => {
    let response = await axiosInstance.get(`/pymethods`)
    return response
}

const getById = async (id: number) => {
    let response = await axiosInstance.get(`/pymethods/${id}`)
    return response
}

const store = async (data: IPaymentMethodPost) => {
    let response = await axiosInstance.post('/pymethods', data)
    return response
}
const update = async (data: IPaymentMethodPut) => {
    let response = await axiosInstance.post('/pymethods', data)
    return response
}
const updateActive = async (data: IPaymentMethodPutActive) => {
    let response = await axiosInstance.post('/pymethods', data)
    return response
}


const pymethodsRepository = {
    getAll,
    getById,
    store,
    update,
    updateActive,
}

export default pymethodsRepository