import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import AxiosInterceptor from './shared/api/axios_interception';
import axios from 'axios';
import { AuthContextProvider } from './shared/contexts/auth';

axios.defaults.withCredentials = true

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <AxiosInterceptor>
      <AuthContextProvider>

        {/* <React.StrictMode> */}
        <App />
        {/* </React.StrictMode> */}
      </AuthContextProvider>
    </AxiosInterceptor>
  </BrowserRouter>
);


