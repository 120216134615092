import { useContext } from "react"
import { ColorModeContext } from "../contexts/theme"



const useColorMode = () => {
    const value = useContext(ColorModeContext)
    return value
}

export default useColorMode