import { PaletteMode, ThemeProvider, createTheme } from '@mui/material';
import React, { useState } from 'react';
import { getDesignTokens } from '../../constants/theme_constants';


interface IColorModeContextProviderProps {
    children: React.ReactNode,

}

export const ColorModeContext = React.createContext({ toggleColorMode: () => { } });

const ColorModeContextProvider: React.FC<IColorModeContextProviderProps> = (props) => {

    const [mode, setMode] = useState<PaletteMode>('light');

    const colorMode = React.useMemo(
        () => ({
            // The dark mode switch would invoke this method
            toggleColorMode: () => {
                setMode((prevMode: PaletteMode) =>
                    prevMode === 'light' ? 'dark' : 'light',
                );
            },
        }),
        [],
    );

    const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                {props.children}
            </ThemeProvider>
        </ColorModeContext.Provider>
    )
}

export default ColorModeContextProvider