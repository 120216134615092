import { stat } from "fs"
import axiosInstance from "../../api/api_client"
import { IPostProductRequest } from "../models/product"
import { IPostSaleRequest, IPutSaleStatusRequisition } from "../models/sale"
import { ApiConstants } from "../../../constants/api_constants"
import { ILoginPost, IUserRegisterPost } from "../models/auth"
import { ICompanyPostRequest } from "../models/company"



const csrf = async () => {
    await axiosInstance.get('/sanctum/csrf-cookie', { baseURL: ApiConstants.baseUrl })
}
const login = async (data: ILoginPost) => {
    let response = await axiosInstance.post(`/login`, data)
    return response
}

const logout = async () => {
    let response = await axiosInstance.get(`/logout`)
    return response
}
const getUser = async () => {
    await csrf()
    let response = await axiosInstance.get(`/auth/user`)
    return response
}

const register = async (data: ICompanyPostRequest) => {
    let response = await axiosInstance.post('/companies',data)
    return response
}



const authRepository = {
    login,
    register,
    logout,
    getUser
}

export default authRepository