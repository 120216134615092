// import Alarm from '@mui/icons-material/Alarm'

import { CssBaseline } from "@mui/material";
import AppRoutes from "./routes/routes";
import ColorModeContextProvider from "./shared/contexts/theme";



function App() {

  return (
    
    <ColorModeContextProvider>
      <CssBaseline />
      <AppRoutes />
    </ColorModeContextProvider>

  );

}

export default App;
