import { Navigate, Outlet, useLocation } from "react-router-dom"
import { RouteHelper } from "../helpers/route_helper"
import { IUserModel } from "../shared/services/models/auth";

interface IAuthProps {
    user?: IUserModel,
    role: number[],
}

export const Authetication = (props: IAuthProps) => {

    const location = useLocation()

    if (!props.user)
        return <Navigate to={RouteHelper.login} state={location.pathname} replace />;

    return <Outlet />
}

export const Unautheticated = (props: { user?: IUserModel }) => {
    if (props.user)
        return <Navigate to={RouteHelper.home} />
    return <Outlet />
}