import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useNavigate } from 'react-router-dom';

interface ISidebarProps {
    name: string,
    submenus: {
        name: string,
        icon: any,
        link: string
    }[],
    expanded: string | false,
    handleExpandedChange: (isExpanded: boolean, panel: string) => void

}

export const SidebarItems: React.FC<ISidebarProps> = (props) => {

    const navigate = useNavigate()

    return (
        <Accordion expanded={props.name === props.expanded} onChange={(event, isExpanded) => props.handleExpandedChange(isExpanded, props.name)}>
            <AccordionSummary
                id='aa-header'
                aria-controls='aa-content'
                expandIcon={<ExpandMoreIcon />}
            >
                {props.name}
            </AccordionSummary>
            <AccordionDetails>
                <List>
                    {
                        props.submenus.map((item, index) => {
                            return (
                                <ListItem key={index} disablePadding>
                                    <ListItemButton onClick={()=>navigate(item.link)} >
                                        <ListItemIcon>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={item.name} />
                                    </ListItemButton>
                                </ListItem>

                            )
                        })
                    }
                </List>
            </AccordionDetails>
        </Accordion>
    );
}