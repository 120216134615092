import { Card, Chip, CardContent, Container, Tooltip, Box, LinearProgress, Grid, Paper, Stack, TextField, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FormatNumber } from '../../helpers/formats'
import { IGetSaleProductResponse, IGetSaleResponse, IPutSaleStatusRequisition } from '../../shared/services/models/sale'
import saleRepository from '../../shared/services/repositories/sale_repository'
import Status from '../../components/status'
import { TimelineDot, TimelineConnector, TimelineContent, TimelineSeparator, TimelineItem, Timeline, LoadingButton, timelineItemClasses } from '@mui/lab'
import { SaleStatus } from '../../constants/api_constants'
import AlertDialogSlide from '../../components/alert_dialog'
import ButtonALertDialog from '../../components/button_dialog'
import ButtonDialogMsg from '../../components/button_dialog_msg'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'

const SalePage = () => {

  const params = useParams()
  const [loadingProducts, setLoadingProducts] = useState(true)
  const [loadingSale, setLoadingSale] = useState(true)

  const [products, setProducts] = useState<IGetSaleProductResponse[]>([])
  const [sale, setSale] = useState<IGetSaleResponse | undefined>()


  const [loadingStatus, setLoadingStatus] = useState<string | undefined>()



  useEffect(() => {
    initialize()
  }, [])

  function initialize() {
    const code = params.code

    if (code !== null) {
      getProducts(code as string)
      getSale(code as string)
    }
  }

  function getSale(code: string) {
    setLoadingSale(true)
    saleRepository
      .getByCode(code)
      .then(res => {
        setSale(res.data)
      })
      .catch(err => {
        alert(err)
      })
      .finally(() => {
        setLoadingSale(false)
      })
  }

  function getProducts(code: string) {
    setLoadingProducts(true)
    saleRepository
      .getProducts(code)
      .then(res => {
        setProducts(res.data)
      })
      .catch(err => {
        alert(err)
      })
      .finally(() => {
        setLoadingProducts(false)
      })
  }

  function submitRefundStatus(reason: string) {
    handleChangeStatus(SaleStatus.DEVOLVIDO, reason)
  }

  function handleChangeStatus(status: string, reason?: string) {


    setLoadingStatus(status)

    const data: IPutSaleStatusRequisition = {
      status: status,
      user: "Edilson Elves",
      reason: reason
    }

    saleRepository
      .updateStatus(sale?.code as string, data)
      .then(res => {
        setSale(res.data)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setLoadingStatus(undefined)
      })



  }

  return (
    <Container sx={{ my: 2, maxWidth: '100vw', position: 'relative' }} >

      <Stack direction={'row'} justifyContent={'flex-end'} spacing={2} marginBottom={2}>

        {
          sale?.refunded || loadingSale ?
            <></> :
            <ButtonDialogMsg
              code={sale?.code ?? ""}
              textbtn='Devolver'
              reazon = {true}
              description={`Deseja devolver o produto ${sale?.code}?`}
              btnProps={{ variant: 'outlined', color: 'error', loading: loadingStatus === SaleStatus.DEVOLVIDO }}
              onConfirm={submitRefundStatus}
            />
        }

        {
          sale?.closed || sale?.refunded || loadingSale ?
            <></> :
            <ButtonDialogMsg
              code={sale?.code ?? ""}
              textbtn='Entregar'
              reazon = {false}
              description={`Deseja devolver o produto ${sale?.code}?`}
              btnProps={{ variant: 'contained', color: 'warning', loading: loadingStatus === SaleStatus.FECHADO }}
              onConfirm={() => handleChangeStatus(SaleStatus.FECHADO)}
            />

        }
      </Stack>
      <Stack spacing={2}>
        <Paper>
          {
            loadingSale ?
              <LinearProgress color='success' />
              : <></>
          }

          <Grid container  >
            <Grid item lg={7}>
              <Card>
                <CardContent>
                  <Chip style={{ borderRadius: 0 }} label={
                    <Typography variant="h5" gutterBottom>
                      {sale?.code}
                    </Typography>
                  }
                    sx={{ paddingTop: 1 }}
                  />

                  <Box>
                    <Table size='small'>
                      <TableBody>
                        <TableRow>
                          <TableCell style={{ fontWeight: 'bold' }}>Origem</TableCell>
                          <TableCell>{sale?.origin}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ fontWeight: 'bold' }}>Cliente</TableCell>
                          <TableCell>{sale?.client_code}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ fontWeight: 'bold' }}>Preço total</TableCell>
                          <TableCell>{FormatNumber.price(sale?.total_price || 0)} MZN</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ fontWeight: 'bold' }}>Estado</TableCell>
                          <TableCell>
                            <Status type={sale?.status || ''} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>

                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={5} >
              <Typography marginTop={1} marginLeft={2} variant='h6'>
                Histórico de ações
              </Typography>
              {
                loadingSale ?
                  <></> :
                  <Box maxHeight={'10rem'} justifyContent={'center'} sx={{ overflowY: 'auto' }}>
                    <Timeline position='right'
                      sx={{
                        [`& .${timelineItemClasses.root}:before`]: {
                          flex: 0,
                          padding: 0,
                        },
                      }}
                    >
                      {
                        sale?.refunded ?
                          <CustomTimelineItem
                            user={sale.refunded.user}
                            date={sale.refunded.date}
                            status={SaleStatus.DEVOLVIDO}
                            reason={sale.refunded.reason}
                          /> : <></>
                      }
                      {
                        sale?.closed ?
                          <CustomTimelineItem
                            user={sale.closed.user}
                            date={sale.closed.date}
                            status={SaleStatus.FECHADO}
                          /> : <></>
                      }

                      <CustomTimelineItem
                        user={sale?.user}
                        date={sale?.created_at as string}
                        status={SaleStatus.PAGO}
                      />

                    </Timeline>
                  </Box>
              }
            </Grid>
          </Grid>
        </Paper>
        <Paper>
          {
            loadingProducts ?
              <LinearProgress color='success' />
              : <></>
          }
          <Table>
            <TableHead>
              <TableCell>Produto</TableCell>
              <TableCell>Quant.</TableCell>
              <TableCell>Unidade</TableCell>
              <TableCell>Preço (MZN)</TableCell>
              <TableCell>Preço total (MZN)</TableCell>
            </TableHead>
            <TableBody>
              {
                products.map((pr, index) => (
                  <TableRow key={index}>
                    <TableCell>{pr.description}</TableCell>
                    <TableCell>{pr.qty}</TableCell>
                    <TableCell>{pr.unity}</TableCell>
                    <TableCell>{FormatNumber.price(pr.price)}</TableCell>
                    <TableCell>{FormatNumber.price(pr.price * pr.qty)}</TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </Paper>
      </Stack>
    </Container>
  )
}


interface ICustomTimelineItemProps {
  user?: string,
  date: string,
  status: string,
  reason?: string,
}
const CustomTimelineItem: React.FC<ICustomTimelineItemProps> = (props) => {
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Box>
          <Box>
            <Status type={props.status} />
            {
              props.reason ?
                <Tooltip title={<Typography>{props.reason}</Typography>} disableInteractive arrow>
                  <InfoRoundedIcon />
                </Tooltip>
                : <></>
            }
          </Box>
          <Box>
            <Typography variant='caption'>
              {props?.user ? 'Por ' + props.user + ', ' : ''}
            </Typography>
            <Typography variant='caption'>
              Em {FormatNumber.fDate(props?.date as string)}
            </Typography>
          </Box>
        </Box>
      </TimelineContent>
    </TimelineItem>
  )
}
export default SalePage