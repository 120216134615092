import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { TransitionProps } from '@mui/material/transitions';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { Height } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IButtonDialogMsgProps {
  onConfirm: (msg: string) => void,
  onClose?: () => void,
  textbtn: string,
  description: string,
  code: string,
  btnProps: LoadingButtonProps,
  reazon?: boolean
}

const ButtonDialogMsg: React.FC<IButtonDialogMsgProps> = (props) => {

  const [open, setOpen] = useState(false)
  const [refundReason, setRefund] = useState<string>("")
  const [code, setCode] = useState<string>("")
  const [codeError, setCodeError] = useState<string>("")
  const [refundReasonError, setRefunddedReasonError] = useState<boolean>(false)

  function handleClose() {
    setOpen(false)
    if (props.onClose)
      props.onClose();

  }
  function handleConfirm() {
    if (code.trim().length < 4) {
      setCodeError("digite os utltimos 4 digitos do código");
    } else if (!props.code.endsWith(code.trim())) {
      setCodeError("codigo inválido");
    } else if (refundReason.trim().length < 1 && props.reazon) {
      setRefunddedReasonError(true)
    } else {
      setCodeError("");
      setOpen(false)
      props.onConfirm(refundReason)
    }
  }

  function handleOnChangeReason(value: string) {
    if (refundReasonError) setRefunddedReasonError(false)
    setRefund(value)
  }
  function handleCodeError(value: string) {
    if (codeError) setCodeError("")
    setCode(value)
  }

  return (
    <Box>
      <LoadingButton
        {...props.btnProps}
        onClick={() => setOpen(true)}
      >
        {props.textbtn}
      </LoadingButton>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        aria-labelledby="customized-dialog-title"
        // aria-describedby="alert-dialog-description"
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: {
            overflow: 'visible',
          },
        }}
      >
        <DialogTitle id='customized-dialog-title'>{"Confirmar"}</DialogTitle>
        <DialogContent id='alert-dialog-slide-description'>
          <Box component="div" sx={{minHeight: '12rem'}}>
            <Typography>
              {props.description}
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              id="code"
              label="Ultimos 4 digitos do código"
              type="text"
              onChange={e => handleCodeError(e.target.value as string)}
              value={code}
              error={codeError.trim().length > 0}
              helperText={codeError}
              // minRows={1}
              fullWidth
              variant="standard"
              minRows={3}
            />
            <Box height={4} mt={1} sx={{ display: props.reazon ? 'block' : 'none' }}>
              <TextField
                autoFocus
                margin="dense"
                id="motivo"
                label="Motivo da devolução"
                type="text"
                onChange={e => handleOnChangeReason(e.target.value as string)}
                value={refundReason}
                error={refundReasonError}
                helperText={refundReasonError ? "Preencha o campo" : ""}
                minRows={2}
                multiline
                fullWidth
                variant="outlined"
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleConfirm}>Continuar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ButtonDialogMsg