import Axios from "axios"
import { ApiConstants } from "../../constants/api_constants"

const axiosInstance = Axios.create({
    baseURL: ApiConstants.baseUrlApi,
    headers: {
        'content-type': 'application/json',
        'accept': 'application/json',

    },
    withCredentials: true,

})

export default axiosInstance