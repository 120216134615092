import { Box } from '@mui/material'
import React from 'react'

interface IBottomPanelProps {
    children: React.ReactNode
}

const BottomPanel: React.FC<IBottomPanelProps> = (props) => {

    return (
        <Box
            sx={{
                position: 'fixed',
                zIndex: 9,
                bottom: 0,
                py: 2, px: 2,
                boxShadow: '0 -1px 16px  rgba(0,0,0,.3)',
                backgroundColor: 'background.default',
                width: '100vw',
                borderTop: '2px solid',
                borderTopColor: 'divider'
            }}
        >
            { props.children }
        </Box>
    )
}

export default BottomPanel