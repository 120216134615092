import axiosInstance from "../../api/api_client"
import { IPostProductRequest } from "../models/product"

const getAll = async (page?: number) => {
    let response = await axiosInstance.get(`/categories`)
    return response
}

const getById = async (id: number) => {
    let response = await axiosInstance.get(`/categories/${id}`)
    return response
}

const store = async (data: IPostProductRequest) => {
    let response = await axiosInstance.post('/categories', data)
    return response
}


const categoryRepository = {
    getAll,
    getById,
    store,
}

export default categoryRepository