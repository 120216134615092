import AddCircleIcon from '@mui/icons-material/AddCircle'
import SearchIcon from '@mui/icons-material/Search'
import { ToggleButton, ToggleButtonGroup } from '@mui/lab'
import { Box, Button, Container, Grid, IconButton, InputAdornment, LinearProgress, Pagination, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Status from '../../components/status'
import { SaleStatus } from '../../constants/api_constants'
import { FormatNumber } from '../../helpers/formats'
import { RouteHelper } from '../../helpers/route_helper'
import { IResponsePaginated } from '../../shared/services/models/response'
import { IGetSaleResponse } from '../../shared/services/models/sale'
import saleRepository from '../../shared/services/repositories/sale_repository'

const SalesPage = () => {

    const [value, setValue] = useState(0);
    const navigate = useNavigate()

    const [prStatus, setPrStatus] = useState<string>('TODOS')
    const [page, setPage] = useState(1)
    const [origin, setOrigin] = useState<string>("")

    const [search, setSearch] = useState<string>("")

    const [saleResponse, setSaleResponse] = useState<IResponsePaginated<IGetSaleResponse[]>>({})

    const [loadingSales, setLoadingSales] = useState(false)

    useEffect(() => {
        getSales(1)
    }, [prStatus, origin])


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    function getSales(pg: number) {
        setPage(pg)
        setLoadingSales(true)
        saleRepository
            .getAll({ origin: origin, search: search }, prStatus, pg)
            .then(res => {
                console.log(res)
                setSaleResponse(res.data)
            })
            .catch(err => {
                alert(err)
                console.log(err)
            })
            .finally(() => {
                setLoadingSales(false)
            })
    }

    return (
        <Container sx={{ my: 2,  position: 'relative' }} >
            <Stack spacing={2}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <Typography variant='h5' >
                        Vendas
                    </Typography>
                    <Button
                        variant='contained'
                        size='small'
                        startIcon={<AddCircleIcon />}
                        onClick={() => navigate(RouteHelper.pdv)}
                    >
                        adicionar
                    </Button>
                </Stack>
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            placeholder='Pesquise por nome, codigo de barras ou sku'
                            size='small'
                            fullWidth
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position='start' >
                                    <IconButton onClick={e=>getSales(page)}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>

                        <FormControl size='small' sx={{ marginRight: 2 }}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={prStatus}
                                onChange={(e) => setPrStatus(old => e.target.value as string)}
                            >
                                {
                                    SaleStatus.toArray().map(ss => <MenuItem value={ss}>{ss}</MenuItem>)
                                }

                            </Select>
                        </FormControl>

                        <ToggleButtonGroup
                            color="primary"
                            size='small'
                            value={origin}
                            exclusive
                            onChange={(e, v) => setOrigin(v as string)}
                            aria-label="Platform"
                        >
                            <ToggleButton value="">TODOS</ToggleButton>
                            <ToggleButton value="marketplace">MARKETPLACE</ToggleButton>
                            <ToggleButton value="LOCAL">LOCAL</ToggleButton>
                        </ToggleButtonGroup>

                    </Grid>
                </Grid>
                <Paper>
                    <Box sx={{ width: '100%', }}>
                        {
                            loadingSales ?
                                <LinearProgress color='success' />
                                : <></>
                        }
                        <Table size='small'>
                            <TableHead>
                                <TableCell>Codigo</TableCell>
                                <TableCell>Origem</TableCell>
                                <TableCell>Preço Total</TableCell>
                                <TableCell>Data</TableCell>
                                <TableCell>Estatus</TableCell>
                                <TableCell></TableCell>
                            </TableHead>
                            <TableBody>
                                {
                                    saleResponse.data?.map((sl, index) => (
                                        <SaleRaw key={index} sale={sl} />
                                    ))
                                }
                            </TableBody>
                        </Table>
                        <Stack
                            direction={'row'}
                            justifyContent={'flex-end'}
                        >
                            <Pagination
                                count={saleResponse?.last_page}
                                page={page}
                                onChange={(e, p) => getSales(p)}
                                sx={{ my: 2 }}
                            />
                        </Stack>
                    </Box>
                </Paper>
            </Stack>
        </Container >
    )
}

const SaleRaw = ({ sale }: { sale: IGetSaleResponse }) => {

    const navigate = useNavigate()

    return (
        < TableRow
            hover
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate(RouteHelper.sale(sale.code))}
        >
            <TableCell>{sale.code}</TableCell>
            <TableCell>{sale.origin}</TableCell>
            <TableCell>{sale.total_price}</TableCell>
            <TableCell>{FormatNumber.fDate(sale.created_at)}</TableCell>
            <TableCell align='center'>
                <Status type={sale.status} />
            </TableCell>
            <TableCell></TableCell>
        </TableRow>
    )
}

export default SalesPage