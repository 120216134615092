import DateRangePicker from 'rsuite/DateRangePicker';

// (Optional) Import component styles. If you are using Less, import the `index.less` file. 
import 'rsuite/DateRangePicker/styles/index.css';

import React from 'react'

function DateFilter() {
  return (
    <DateRangePicker format="MM/yyyy" character=" – "  defaultValue={[new Date(), new Date()]} />
  )
}

export default DateFilter