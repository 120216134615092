import React, { useEffect } from 'react';
import axiosInstance from './api_client';
import { AppConst } from '../../constants/app_constants';


const AxiosInterceptor = (props: { children: React.ReactNode }) => {


    useEffect(() => {

        // Request interceptor
        const requestInterceptor = axiosInstance.interceptors.request.use((request) => {
            let token = localStorage.getItem(AppConst.name)
            // if (request.headers && token)
            if (request.headers)
                request.headers.Authorization = token ? `Bearer ${token}`: ''
            return request;
        });

        // Response interceptor
        const responseInterceptor = axiosInstance.interceptors.response.use((response) => {
            return response;
        }, (error) => {

            let obj = {}

            if (error?.response?.data) {
                obj = {
                    data: error?.response?.data,
                    status: error?.response?.status
                }
            } else {
                obj = error
                console.log(error)
            }

            return Promise.reject(obj);
        });

        return () => {
            axiosInstance.interceptors.request.eject(requestInterceptor);
            axiosInstance.interceptors.response.eject(responseInterceptor);
        };

    }, []);

    return (
        <>
            {props.children}
        </>
    )
};

export default AxiosInterceptor;