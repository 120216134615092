import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useState } from 'react';
import AppBarTop from '../../components/appbar';
import Sidebar from '../../components/sidebar';
import { Outlet } from 'react-router-dom';

const drawerWidth = 240;


export default function MainLayout() {


    const [open, setOpen] = useState(false);

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    return (
        <Box sx={{ display: 'flex', maxWidth: '100vw' }}>

            <AppBarTop drawerProps={{ open: open, width: drawerWidth, handleDrawerToggle: handleDrawerToggle }} />
            <Sidebar drawerWidth={drawerWidth} handleDrawerToggle={handleDrawerToggle} open={open} />

            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    minHeight: '80vh',
                    width: { lg: open ? `calc(100% - ${drawerWidth}px)` : '100%' }
                }}
            >
                <Toolbar />
                <Outlet />
            </Box>
        </Box >
    );
}