import AddCircleIcon from '@mui/icons-material/AddCircle'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Pagination } from '@mui/lab'
import { Box, Button, Container, LinearProgress,IconButton, Paper, Skeleton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FormatNumber } from '../../helpers/formats'
import { IGetReleasesResponse } from '../../shared/services/models/release'
import { IResponsePaginated } from '../../shared/services/models/response'
import { IGetStockResponse } from '../../shared/services/models/stock'
import releasesRepository from '../../shared/services/repositories/releases_repository'
import stockRepository from '../../shared/services/repositories/stock_repository'
import { ReleaseDrawer } from './release_drawer'


const CheckStockProductPage = () => {

    const [value, setValue] = useState(0);
    const [stockItem, setSockItem] = useState<IGetStockResponse>()
    const [pageLoading, setPageLoading] = useState(true)
    const [pageError, setPageError] = useState('')
    const params = useParams()
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(true)
    const [fetch, setFetch] = useState(true)
    const [releases, setReleases] = useState<IResponsePaginated<IGetReleasesResponse[]>>()

    useEffect(() => {

        const id = Number(params.id || 0)

        stockRepository
            .getById(id)
            .then(response => {
                setSockItem(response.data)
                console.log(response.data.id)
            })
            .catch(error => {
                console.log(error)
                setPageError(error.data.message)
            })
            .finally(() => {
                setPageLoading(false)
            })
    }, [])



    useEffect(() => {

        setLoading(true)

        releasesRepository
            .getAll(Number(params.id || 0), page)
            .then(response => {
                setReleases(response.data)
            })
            .catch(error => {
                alert(error.message)
            })
            .finally(() => {
                setLoading(false)
            })

    }, [page, fetch])

    async function handleChangePage(p: number) {
        setPage(p)
    }


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const [open, setOpen] = useState(false);

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    function addRelase(release: IGetReleasesResponse) {
        setFetch(old=>!old)
    }


    if (pageError)
        return <>{pageError}</>

    return (
        <>
            <Container sx={{ my: 2, maxWidth: '100vw', position: 'relative' }} >
                <Stack>
                    <Stack direction={'row'} justifyContent='space-between'>
                        <Box>
                            <Typography variant='h4' >
                                {stockItem?.description}
                            </Typography>
                            <Typography variant='body1' sx={{ mb: 2 }} >
                                {stockItem?.sku}
                     
                            </Typography>
                        </Box>
                        <Box>
                            <Button
                                variant='contained'
                                size='small'
                                startIcon={<AddCircleIcon />}
                                onClick={handleDrawerToggle}
                            >
                                Adicionar lançamento
                            </Button>
                        </Box>
                    </Stack>
                </Stack>
                <Paper sx={{ borderRadius: '0', mt: 2 }}>
                
                    <Box sx={{ width: '100%', }}>
                       
                            <Stack spacing={2}>
                            {
                                loading ?
                                    <LinearProgress color='success' />
                                    : <></>
                            }
                                <Table size='small'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Data e hora</TableCell>
                                            <TableCell>Quntidade</TableCell>
                                            <TableCell>Observação</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            releases?.data?.map((item, index) => {
                                                return (
                                                    <TableRow key={index}>
    
                                                        <TableCell>{FormatNumber.fDate(item.created_at as string)}</TableCell>
                                                        <TableCell>{item.qty}</TableCell>
                                                        <TableCell>{item.observation}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                                <Stack
                                    direction={'row'}
                                    justifyContent={'flex-end'}
                                >
                                    <Pagination
                                        count={releases?.last_page}
                                        page={page}
                                        onChange={(e, p) => handleChangePage(p)}
                                        sx={{ my: 2 }}
                                    />
                                </Stack>
                            </Stack>
                       
                    </Box>
                </Paper>
                <ReleaseDrawer
                    open={open}
                    deposit_id={1}
                    product_id={stockItem?.id as number}
                    handleDrawerToggle={handleDrawerToggle}
                    onCreate={addRelase}
                />
            </Container>
        </>

    )
}


export default CheckStockProductPage