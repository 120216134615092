import LockIcon from '@mui/icons-material/Lock'
import { Box, Button, Grid, InputAdornment, Paper, Stack, TextField, Toolbar, Typography, MenuItem } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { RouteHelper } from '../../helpers/route_helper'
import authRepository from '../../shared/services/repositories/auth_repository'
import { useEffect, useState } from 'react'
import { AppConst } from '../../constants/app_constants'
import { LoadingButton } from '@mui/lab'
import { ILoginPost } from '../../shared/services/models/auth'
import { useAuth } from '../../shared/hooks/useAuth'
import { HomeMini, HomeOutlined, LocalActivity, LocationCity, LocationCitySharp, LocationOn, Phone } from '@mui/icons-material'
import Lock from '@mui/icons-material/Lock'
import bairrosRepository from '../../shared/services/repositories/bairros_repository'
import { IBairroResponse } from '../../shared/services/models/bairro'
import { ICompanyPostRequest } from '../../shared/services/models/company'

const loginValues: ICompanyPostRequest = {
  address: '',
  password_confirmation: '',
  email: '',
  latitude: '',
  longitude: '',
  name: '',
  neighborhood_id: 0,
  nuit: '',
  password: '',
  phone1: '',
  phone2: '',
  phone_number1: '',
  phone_number2: '',
}

interface IErrorsValues {
  username?: string,
  password?: string,
  message?: string,
}

const RegisterPage = () => {

  const navigate = useNavigate()
  const [fields, setFields] = useState<ICompanyPostRequest>(loginValues)
  const [errors, setErrors] = useState<ICompanyPostRequest | undefined>({})
  const [loading, setLoading] = useState(false)
  const [loadingBairros, setLoadingBairros] = useState(false)
  const [bairros, setBairros] = useState<IBairroResponse[]>([])
  const location = useLocation()
  const { setAuth } = useAuth()

  useEffect(() => {
    getBairros();
  }, [])

  function getBairros() {
    setLoadingBairros(true)
    bairrosRepository
      .getAll()
      .then(res => {
        setBairros(res.data)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setLoadingBairros(false)
      })
  }

  function handleLogin() {

    setLoading(true)

    authRepository
      .register(fields)
      .then(res => {
        alert("Registado com sucesso!")

        //redireciona para a pagina pretendida
        navigate(RouteHelper.login, { replace: true })
      })
      .catch(err => {
        console.log(err)
        setErrors(err.data.errors)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  function cleanFields() {
    setFields(loginValues);
    setErrors({});
  }

  return (
    <>
      {/* <AppBarTop drawerProps={{ open: false, width: 0, handleDrawerToggle: () => {} }} /> */}
      {/* <Toolbar /> */}
      <Box sx={{ width: '100%', minHeight: '100vh' }} >
        <Toolbar />
        <Grid container justifyContent={'center'}>
          <Grid item xs={10} md={10} lg={8} >
            <Paper
              sx={{ height: '100%', py: 4, px: 3 }}
            >
              <Stack direction='column' spacing={2} justifyContent={'center'} >
                <Stack direction='column' spacing={2} alignItems={'center'} >
                  <Typography variant='h4' color={'GrayText'}>
                    Gueva Admin
                  </Typography>
                  <Typography variant='h6' color={'GrayText'}>
                    Criar uma conta
                  </Typography>
                </Stack>
                <Grid container spacing={2} justifyContent={'center'}>
                  <Grid item xs={10} md={8} lg={6} >
                    <Stack direction='column' spacing={2} alignContent={'center'} >

                      <TextField
                        value={fields.name}
                        onChange={e => setFields(old => ({ ...old, name: e.target.value }))}
                        label='Nome'
                        error={errors?.name !== undefined}
                        type='text'
                        helperText={errors?.name}
                      // InputProps={{
                      //   startAdornment: <InputAdornment position='start'>@</InputAdornment>
                      // }}
                      />
                      <TextField
                        value={fields.email}
                        onChange={e => setFields(old => ({ ...old, email: e.target.value }))}
                        label='Email'
                        error={errors?.email !== undefined}
                        type='email'
                        helperText={errors?.email}
                        InputProps={{
                          endAdornment: <InputAdornment position='start'>@</InputAdornment>
                        }}
                      />
                      <Grid container justifyContent={'space-between'}>
                        <Grid item md={6}>
                          <TextField
                            value={fields.phone_number1}
                            onChange={e => setFields(old => ({ ...old, phone_number1: e.target.value }))}
                            label='Celular'
                            error={errors?.phone_number1 !== undefined}
                            type='tel'
                            helperText={errors?.phone_number1}
                            InputProps={{
                              startAdornment: <InputAdornment position='start'><Phone /></InputAdornment>
                            }}
                          />
                        </Grid>
                        <Grid item md={5}>
                          <TextField
                            value={fields.phone_number2}
                            onChange={e => setFields(old => ({ ...old, phone_number2: e.target.value }))}
                            label='Celular'
                            error={errors?.phone_number2 !== undefined}
                            type='tel'
                            helperText={errors?.phone_number2}
                            InputProps={{
                              startAdornment: <InputAdornment position='start'><Phone /></InputAdornment>
                            }}
                          />
                        </Grid>
                      </Grid>
                      {/* <Box>{errors?.phone_numbers}</Box> */}
                      <TextField
                        value={fields.nuit}
                        onChange={e => setFields(old => ({ ...old, nuit: e.target.value }))}
                        label='Nuit'
                        type='number'
                        error={errors?.nuit !== undefined}
                        helperText={errors?.nuit}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={10} md={7} lg={6}>
                    <Stack direction='column' spacing={2} >
                      <Grid container justifyContent={'space-between'}>
                        <Grid item md={6}>
                          <TextField select
                            fullWidth
                            type='number'
                            label='Bairro'
                            error={errors?.neighborhood_id !== undefined}
                            value={fields.neighborhood_id}
                            onChange={e => setFields(old => ({ ...old, neighborhood_id: Number(e.target.value) }))}
                            helperText={errors?.neighborhood_id}
                            InputProps={{
                              endAdornment: <InputAdornment position='start'><LocationCitySharp /></InputAdornment>
                            }}
                          >
                            <MenuItem key={0} value={0}></MenuItem>
                            {
                              bairros.map((item, index) => {
                                return (
                                  <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                )
                              })
                            }
                          </TextField>
                        </Grid>
                        <Grid item md={5}>
                          <TextField
                            value={fields.address}
                            onChange={e => setFields(old => ({ ...old, address: e.target.value }))}
                            label='Endereco'
                            error={errors?.address !== undefined}
                            type='text'
                            helperText={errors?.address}
                            InputProps={{
                              endAdornment: <InputAdornment position='start'><HomeOutlined /></InputAdornment>
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container justifyContent={'space-between'}>
                        <Grid item md={6}>
                          <TextField
                            value={fields.longitude}
                            onChange={e => setFields(old => ({ ...old, longitude: e.target.value }))}
                            label='Longitude'
                            error={errors?.longitude !== undefined}
                            type='number'
                            helperText={errors?.longitude}
                            InputProps={{
                              endAdornment: <InputAdornment position='start'><LocationOn /></InputAdornment>
                            }}
                          />
                        </Grid>
                        <Grid item md={5}>
                          <TextField
                            value={fields.latitude}
                            onChange={e => setFields(old => ({ ...old, latitude: e.target.value }))}
                            label='Latitude'
                            error={errors?.latitude !== undefined}
                            type='number'
                            helperText={errors?.latitude}
                            InputProps={{
                              endAdornment: <InputAdornment position='start'><LocationOn /></InputAdornment>
                            }}
                          />
                        </Grid>
                      </Grid>
                      <TextField
                        value={fields.password}
                        onChange={e => setFields(old => ({ ...old, password: e.target.value }))}
                        label='Senha'
                        error={errors?.password !== undefined}
                        type='password'
                        helperText={errors?.password}
                        InputProps={{
                          endAdornment: <InputAdornment position='start'><Lock /></InputAdornment>
                        }}
                      />
                      <TextField
                        value={fields.password_confirmation}
                        onChange={e => setFields(old => ({ ...old, password_confirmation: e.target.value }))}
                        label='Confirmar senha'
                        error={errors?.password_confirmation !== undefined}
                        type='password'
                        helperText={errors?.password_confirmation}
                        InputProps={{
                          endAdornment: <InputAdornment position='start'><Lock /></InputAdornment>
                        }}
                      />
                    </Stack>
                  </Grid>
                </Grid>
                <Stack direction={'row'} justifyItems={'flex-end'}>
                  <Button onClick={() => navigate(RouteHelper.login)}>
                    Tenho uma conta! Fazer login
                  </Button>
                </Stack>
                <Stack direction={'row'} justifyContent={'flex-end'} spacing={2} alignItems={'center'}>
                  <Button onClick={cleanFields}>
                    Cancelar
                  </Button>
                  <LoadingButton
                    sx={{ mt: 2, borderRadius: '0' }}
                    variant='contained'
                    onClick={handleLogin}
                    loading={loading}
                  >
                    Registar
                  </LoadingButton>
                </Stack>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default RegisterPage