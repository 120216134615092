import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';

interface TopProductsChartProps {
  data: { product: string; sales: number }[];
}

const TopProductsChart: React.FC<TopProductsChartProps> = ({ data }) => {
  return (
    <BarChart width={800} height={250} data={data}>
      <XAxis dataKey="product" />
      <YAxis />
      <Tooltip />
      <CartesianGrid stroke="#f5f5f5" />
      <Bar dataKey="sales" fill="#8884d8" />
    </BarChart>
  );
};

export default TopProductsChart;
