import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';

interface SalesByMonthChartProps {
  data: { month: string; sales: number }[];
}

const SalesByMonthChart: React.FC<SalesByMonthChartProps> = ({ data }) => {
  return (
    <LineChart width={1000} height={250} data={data}>
      <XAxis dataKey="month" />
      <YAxis />
      <Tooltip />
      <CartesianGrid stroke="#f5f5f5" />
      <Line type="monotone" dataKey="sales" stroke="#8884d8" />
    </LineChart>
  );
};

export default SalesByMonthChart;
