export class FormatNumber {

    static price(num: number) {
        return num.toLocaleString('pt-br', { minimumFractionDigits: 2 })
        // return num.toLocaleString('pt',{style: 'currency', currency: 'MZN'});
    }

    static fDate(date: string) {

        const dateObject = new Date(date)

        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        };
       return dateObject.toLocaleString('en-US', options);

    }
}