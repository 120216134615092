import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { Avatar, Box, Drawer, IconButton, Typography } from '@mui/material'

import React from 'react'
import SidebarContent from './sidebar_items'
import { useAuth } from '../../shared/hooks/useAuth'
import { Logout } from '@mui/icons-material'

interface ISidebarProps {
    open: boolean,
    drawerWidth: number,
    handleDrawerToggle: () => void,
}

const Sidebar: React.FC<ISidebarProps> = (props) => {

    const { auth } = useAuth()

    return (
        <Box
            component="nav"
            sx={{ width: { lg: props.open ? props.drawerWidth : '0' }, overflowX: 'hidden', }}
            aria-label="mailbox folders"
            overflow={'hidden'}

        >
            <Drawer
                variant="persistent"
                open={props.open}
                onClose={props.handleDrawerToggle}

                ModalProps={{
                }}
                sx={{
                    overflowX: 'hidden',
                    display: { xs: 'block', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: props.drawerWidth },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        p: 2,
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography variant='subtitle2'>
                        <Avatar>
                            {auth?.user?.username.charAt(0).toLocaleUpperCase()}
                        </Avatar>
                        {auth?.user?.username}
                    </Typography>

                    <IconButton onClick={props.handleDrawerToggle} sx={{ display: props.open ? 'block' : 'hidden', }}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Box>
                <SidebarContent />
                
            </Drawer>

        </Box>
    )
}



export default Sidebar