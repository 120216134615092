import FilterListIcon from '@mui/icons-material/FilterList'
import HomeOutlinedtIcon from '@mui/icons-material/HomeOutlined'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import SearchIcon from '@mui/icons-material/Search'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Button, Container, Grid, IconButton,LinearProgress, InputAdornment, Pagination, Paper, Skeleton, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { RouteHelper } from '../../helpers/route_helper'
import { useEffect, useState } from 'react'
import { IGetStockResponse } from '../../shared/services/models/stock'
import { IResponsePaginated } from '../../shared/services/models/response'
import stockRepository from '../../shared/services/repositories/stock_repository'

const SellerPage = () => {

    const [stock, setStock] = useState<IResponsePaginated<IGetStockResponse[]>>()
    const [page, setPage] = useState(1)
    const [pageLoading, setPageLoading] = useState(true)
    const [stockLoading, setStockLoaging] = useState(true)


    useEffect(() => {
        (
            async () => {
                await getProducts({ first: true })
                setPageLoading(false)
            }
        )();

    }, [])

    async function getProducts(data: { page?: number, first?: boolean }) {

        setStockLoaging(true)

        await stockRepository
            .getAll(data.page || 1)
            .then(response => {
                console.log(response.data)
                setPage(data.page || 1)
                setStock(response.data)
            })
            .catch(error => {
                if (error?.data)
                    console.log(error?.data?.message)
                console.log(error.code)
            })
            .finally(() => {
                setStockLoaging(false)
            })
    }

    async function handleChangePage(p: number) {
        await getProducts({ page: p })
    }



    return (
        <Container sx={{ my: 2, maxWidth: '100vw', position: 'relative' }} >
            <Stack spacing={2}>
                <Typography variant='h5' >
                    Gerir o estoque
                </Typography>
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            placeholder='Pesquise por nome, codigo de barras ou sku'
                            size='small'
                            fullWidth
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position='start' >
                                        <IconButton>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Button startIcon={<FilterListIcon />} variant='outlined' size='small' sx={{ borderRadius: 6, mr: 1 }}>
                            filtros
                        </Button>
                        <Button startIcon={<RemoveCircleIcon />} variant='outlined' size='small' sx={{ borderRadius: 6 }}>
                            limpar filtros
                        </Button>

                    </Grid>
                </Grid>
                <Paper>
                    {
                        pageLoading ?
                            <LinearProgress color='success' />
                            : <></>
                    }
                    <StockTable stock={stock?.data || []} />
                    <Stack
                        direction={'row'}
                        justifyContent={'flex-end'}
                    >
                        <Pagination
                            count={stock?.last_page}
                            page={page}
                            onChange={(e, p) => handleChangePage(p)}
                            sx={{ my: 2 }}
                        />
                    </Stack>
                </Paper>
            </Stack>
        </Container>
    )

}

interface IStockTableProps {
    stock: IGetStockResponse[]
}
const StockTable: React.FC<IStockTableProps> = (props) => {

    const navigate = useNavigate()

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Produto</TableCell>
                    <TableCell>Código (SKU)</TableCell>
                    <TableCell>Preço</TableCell>
                    <TableCell>Estoque físico</TableCell>
                    <TableCell>Estoque reservado</TableCell>
                    <TableCell>Estoque disponível</TableCell>
                    <TableCell>Unidade</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    props.stock.map((stockItem, index) => {
                        return (
                            <TableRow
                                hover
                                sx={{ cursor: 'pointer' }}
                                onClick={() => navigate(RouteHelper.productCheckStock(stockItem.id))}
                            >

                                <TableCell>{stockItem.description}</TableCell>
                                <TableCell>{stockItem.sku}</TableCell>
                                <TableCell>{stockItem.price}</TableCell>
                                <TableCell>{stockItem.qty}</TableCell>
                                <TableCell>{stockItem.qty_reserved}</TableCell>
                                <TableCell>{stockItem.qty_available}</TableCell>
                                <TableCell>{stockItem.unity}</TableCell>
                            </TableRow>
                        )
                    })
                }
            </TableBody>
        </Table>
    )
}

export default SellerPage