import { Box, Button, Drawer, FormControl, InputAdornment, InputLabel, MenuItem, Select, Stack, TableContainer, TextField, Typography, Table, TableRow, TableBody, TableCell, TableHead } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import React, { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { IPayementMethod } from '../../shared/services/models/payment-method'
import { ISelectedProduct } from '.'
import { FormatNumber } from '../../helpers/formats'

interface ISellDrawerProps {
    open: boolean,
    handleDrawerToggle: () => void,
    pyMethods: IPayementMethod[],
    setPyMethod: React.Dispatch<React.SetStateAction<IPayementMethod[]>>,
    totalPrice: number,
    handleCancel: () => void,
    handleSubmit: (payMethod: number) => void,
    products: ISelectedProduct[],
    loadingSave: boolean,
    loadingPayments: boolean,
}

interface IErrors {
    payMethod?: string
}

const SellDrawer: React.FC<ISellDrawerProps> = (props) => {

    const [received, setReceived] = useState(0)
    const [payMethod, setPayMethod] = useState(-1)
    const [errors, setErrors] = useState<IErrors>({})

    function handleCancel() {
        setReceived(0)
        setPayMethod(0)
        props.handleCancel()
    }

    function handleSubmit() {
        if (payMethod === 0) {
            setErrors(old => ({
                ...old,
                payMethod: 'Escolha um metodo de pagamento'
            }))
        } else {
            setErrors(old => ({}))
            props.handleSubmit(payMethod)
        }
    }

    return (
        <Drawer
            anchor='right'
            open={props.open}
            sx={{
                overflowX: 'hidden',
                '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: { xs: '100%', md: 600 }
                },
            }}
        >
            <Box p={4} sx={{ height: '100%' }}>

                <Stack direction={'row'} justifyContent={'space-between'} mb={2}>
                    <Stack direction={'row'} spacing={1} alignItems={'flex-end'}>
                        <Typography variant='h6'>
                            Finalizar Venda
                        </Typography>
                    </Stack>
                    <Button endIcon={<CloseIcon />} size={'small'} onClick={props.handleDrawerToggle}>
                        Fechar
                    </Button>
                </Stack>

                <Stack sx={{ height: '90%' }} justifyContent={'space-between'}>
                    <Stack spacing={2}>


                        <Stack spacing={1}>
                            <Typography>Forma de pagamento</Typography>
                            <TextField select
                                fullWidth
                                label='Forma de pagamento'
                                placeholder='Tipo'
                                variant='filled'
                                value={payMethod}
                                onChange={(e) => setPayMethod(Number(e.target.value))}
                                helperText={errors?.payMethod}
                                error={typeof errors?.payMethod !== 'undefined'}
                            >

                                {
                                    props.loadingPayments ?
                                        <MenuItem key={-1} disabled>loading...</MenuItem>
                                        :
                                        props.pyMethods.map((pyM, index) => {
                                            return <MenuItem key={index} value={pyM.id}>{pyM.name}</MenuItem>
                                        })
                                }
                            </TextField>
                        </Stack>

                        <Stack spacing={1}>
                            <Typography>Valor recevido</Typography>
                            <Stack direction={'row'} spacing={2}>

                                <TextField
                                    required
                                    fullWidth
                                    type='number'
                                    label='Valor Recebido'
                                    variant='filled'
                                    InputProps={{
                                        endAdornment: <InputAdornment position='start'>MZN</InputAdornment>
                                    }}
                                    value={received}
                                    onChange={e => setReceived(Number(e.currentTarget.value))}
                                    helperText={''}
                                    error={false}
                                />

                                <Stack sx={{ marginLeft: '2rem' }}>
                                    <Typography variant='body1' color='red'>Troco</Typography>
                                    <Stack direction={'row'} spacing={1}>
                                        <Typography variant='body2' color={'red'} fontWeight={550}>MZN</Typography>
                                        <Typography variant='h4' color={'red'} fontWeight={600}>{FormatNumber.price(received - props.totalPrice)}</Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>

                        <TableContainer sx={{ maxHeight: 500 }} >
                            <Table sx={{ minWidth: 400 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="right">Descrição</TableCell>
                                        <TableCell align="right">Quantidade</TableCell>
                                        <TableCell align="right">Preço unitário</TableCell>
                                        <TableCell align="right">Preço Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.products.map((row) => (
                                        <TableRow hover key={row.id}>
                                            <TableCell align="right">{row.description}</TableCell>
                                            <TableCell align="right">{row.selectedQty}</TableCell>
                                            <TableCell align="right">{FormatNumber.price(row.price)} MZN</TableCell>
                                            <TableCell align="right">{FormatNumber.price(row.totalPrice)} MZN</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>


                    </Stack>


                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Stack sx={{ marginLeft: '2rem' }}>
                            <Typography variant='body1' color='grey'>Preço Total</Typography>
                            <Stack direction={'row'} spacing={1}>
                                <Typography variant='body2' fontWeight={550}>MZN</Typography>
                                <Typography variant='h4' fontWeight={600}>{FormatNumber.price(props.totalPrice)}</Typography>
                            </Stack>
                        </Stack>
                        <Box>
                            <Button
                                sx={{ marginRight: 2 }}
                                onClick={handleCancel}
                            >
                                Cancelar
                            </Button>
                            <LoadingButton
                                variant='contained'
                                size='large'
                                loading={props.loadingSave}
                                onClick={handleSubmit}
                            >
                                Finalizar
                            </LoadingButton>
                        </Box>
                    </Stack>
                </Stack>
            </Box>
        </Drawer>
    )
}

export default SellDrawer