import React, { useCallback, useMemo } from 'react'
import { Chip } from '@mui/material'
import { SaleStatus } from '../../constants/api_constants'

const Status = ({ type }: { type: string }) => {

    const color = () => {
        if (type == SaleStatus.PAGO) return 'success'
        else if (type == SaleStatus.FECHADO) return 'warning'
        else if (type == SaleStatus.DEVOLVIDO) return 'error'
        else return 'info'
    }

    return (
        <Chip label={type} color={color()} size='small' />
    )
}

export default Status