import { PaletteMode } from "@mui/material";
import { grey,  } from "@mui/material/colors";

export const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode
    ,
    ...(mode === 'light'
      ? {
        // palette values for light mode
        // primary: {
        //   main: brown[300]
        // },
        // divider: amber[200],
        // text: {
        //   primary: grey[900],
        //   secondary: grey[800],
        // },
        background:{
          default: grey[50],
          paper: '#fcfcfc'
        }
      }
      : {
        // // palette values for dark mode
        // primary: deepOrange,
        // divider: deepOrange[700],
        // background: {
        //   default: deepOrange[900],
        //   paper: deepOrange[900],
        // },
        // text: {
        //   primary: '#fff',
        //   secondary: grey[500],
        // },
      }),
  },
});
