import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { IconButton, InputAdornment, TextField } from '@mui/material'

interface INumberChangerProps {
    setValue: React.Dispatch<React.SetStateAction<number>>,
    value: number,
    min?: number,
    max: number,
    disabled?: boolean
}

const NumberChanger: React.FC<INumberChangerProps> = (props) => {

    function handleAdd(add: boolean, value?: string) {
        if (value) {
            const newValue = Number(value)
            if (newValue > 0) {
                if (newValue > props.max) {
                    props.setValue(old => props.max)
                } else
                    props.setValue(old => newValue)
            }
        } else if (add) {
            if ((props.value + 1) > props.max)
                props.setValue(old => props.max)
            else
                props.setValue(old => old + 1)
        } else {
            if (props.value > 0) {
                if ((props.value - 1) > props.max)
                    props.setValue(old => props.max)
                else
                    props.setValue(old => old - 1)
            }

        }
    }

    return (
        <TextField
            value={props.value}
            onChange={e => handleAdd(false, e.currentTarget.value)}
            disabled={props.disabled}
            type="number"
            size='small'
            inputProps={{
                min: 0,      // Minimum allowed value
                max: props?.max as number,    // Maximum allowed value
                step: 1,     // Step size (increment/decrement)
            }}
        />
        // <TextField
        //     value={props.value}
        //     onChange={e => handleAdd(false, e.currentTarget.value)}
        //     variant='outlined'
        //     // label='Quantidade'
        //     size='small'
        //     sx={{ m: 1, maxWidth: 150 }}
        //     disabled={props.disabled}
        //     InputProps={{
        //         type: 'number',
        //         startAdornment:
        //             <InputAdornment position="start">
        //                 <IconButton onClick={() => handleAdd(false)} disabled={props.disabled}>
        //                     <RemoveIcon />
        //                 </IconButton>
        //             </InputAdornment>,
        //         endAdornment:
        //             <InputAdornment position="end">
        //                 <IconButton onClick={() => handleAdd(true)} disabled={props.disabled}>
        //                     <AddIcon />
        //                 </IconButton>
        //             </InputAdornment>
        //     }}
        // />
    )
}

export default NumberChanger