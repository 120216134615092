export class RouteHelper {

    static initialPage = '/'
    static login = '/login'
    static sellers = '/vendedor'
    static home = '/home'
    static pdv = '/pdv'
    static register = '/registar'
    static products = '/produtos'
    static checkStock = '/controlar-estoque'
    static sellOrder = '/pedido-compra'
    static sales = '/vendas'
    static companty = '/empresa'
    static integation = '/intrgarcao'
    static addProducts = `${this.products}/adicionar`

    static updateProductrel = `${this.products}/:id/atualizar`
    static updateProduct = (id: number) => `${this.products}/${id}/atualizar`

    static productCheckStockrel = `${this.checkStock}/:id`
    static productCheckStock = (id: number) => `${this.checkStock}/${id}`

    static saleRel = `${this.sales}/:code`
    static sale = (code: string) => `${this.sales}/${code}`



}