import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { LoadingButton, } from '@mui/lab';
import { Box, Button, Container, Hidden, Paper, Skeleton, Stack, Tab, Tabs, Toolbar, Typography, } from '@mui/material';
import { useEffect, useState } from 'react';
import BottomPanel from '../../components/bottom_panel';
import TabPanel from '../../components/tab_panel';
import { ProductType } from '../../constants/api_constants';
import { IGetProdutResponse, IPostProductErrorsRequest, IPostProductRequest } from '../../shared/services/models/product';
import productRepository from '../../shared/services/repositories/product_repository';
import ComplementaryInfoTab from './complementar_info';
import GeralInfoTab from './geral_info';
import { ICategoriesResponse } from '../../shared/services/models/category';
import categoryRepository from '../../shared/services/repositories/category_repository';
import ImageUpload from './image_upload';
import { useParams } from 'react-router-dom';

const initialFormValues: IPostProductRequest = {
  qty: 0,
  bar_code: '',
  sku: '',
  type: ProductType.product,
  brand: '',
  description: '',
  price: 0,
  attributes: '',
  check_validity: 1,
  initial_stock: 0,
  minimum_stock: 2,
  maximum_stock: 2,
  gross_weight: 0,
  net_weight: 0,
  lenght: 0,
  width: 0,
  height: 0,
  unity: 'Un',
  complemtary_description: '',
  category_id: 1,
  active: true,
  id: 0,
}

interface IAddProductsPageProps {
  isEditPage?: boolean
}
const AddProductsPage: React.FC<IAddProductsPageProps> = (props) => {

  const [value, setValue] = useState(0);
  const [formLoading, setFormLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [images, setImages] = useState<string[]>([])
  const [formValues, setFormValues] = useState<IPostProductRequest>(initialFormValues)
  const [product, setProduct] = useState<IGetProdutResponse>()
  const [formErrors, setFormErrors] = useState<IPostProductErrorsRequest>()
  const [categories, setCategories] = useState<ICategoriesResponse[]>([])

  const params = useParams()

  useEffect(() => {

    let id = Number(params.id);
    
    if (props.isEditPage) {
      productRepository
        .getById(id)
        .then(res => {
          setFormValues(res.data)
          setImages(old => JSON.parse(res.data.imgs_url||'[]'))
          console.log(res.data)
        })
        .catch(err => {
        })
        .finally(() => {
          console.log('form-values', formValues)
          setPageLoading(false)
        })
    }

    categoryRepository
      .getAll()
      .then(response => {
        setCategories(response.data)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  function handleChange(event: React.SyntheticEvent, newValue: number) {
    setValue(newValue);
  };

  function handleStore() {

    setFormLoading(true)

    console.log(formValues)
    if (props.isEditPage) {
      productRepository
        .update(formValues,formValues.id as number)
        .then(response => {
          alert('actuaizado')
          setFormErrors(undefined)
        })
        .catch(error => {

          if (error?.data)
            setFormErrors(error?.data.errors)
          console.log('error', error.data)

        }).finally(() => {
          setFormLoading(false)
        })
    } else {

      setFormValues(old => ({ ...old, imgs_url: undefined }))

      productRepository
        .store(formValues)
        .then(response => {
          alert('salvo')
          setFormValues(initialFormValues)
          setFormErrors(undefined)
          setProduct(response.data)

        })
        .catch(error => {

          if (error?.data)
            setFormErrors(error?.data.errors)
          console.log('error', error.data)

        }).finally(() => {
          setFormLoading(false)
        })
    }
  }

  const handleUpdateImgs = (imgs: string[]) => {
    setImages(old => imgs)
  }

  if (pageLoading && props.isEditPage)
    return (
      <Skeleton
        width={'80%'}
        height={'80vh'}
      />
    )

  return (
    <>
      <Container sx={{ my: 2, maxWidth: '100vw', position: 'relative' }} >
        <Stack spacing={3}>
          <Typography variant='h5' >
            Adicionar Produto
          </Typography>
          <Paper sx={{ borderRadius: '0' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                allowScrollButtonsMobile
              >
                <Tab label="Dados Gerais" />
                <Tab label="Dados complementares" />
                <Tab label="Imagens" />
              </Tabs>
            </Box>
            <Box sx={{ width: '100%', }}>
              <TabPanel value={value} index={0}>
                <GeralInfoTab
                  {...formValues}
                  errors={formErrors}
                  setFormValues={setFormValues}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <ComplementaryInfoTab
                  {...formValues}
                  errors={formErrors}
                  categories={categories}
                  setFormValues={setFormValues}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <ImageUpload
                  productId={product?.id || formValues.id as number}
                  IsToEdit={props.isEditPage}
                  imgs={images}
                  updateImgs={handleUpdateImgs}
                />
              </TabPanel>
            </Box>
          </Paper>
          <Toolbar />
        </Stack>

      </Container>
      <BottomPanel>
        <Stack direction={'row'} spacing={2} justifyContent={'flex-start'}>
          <Button
            variant='outlined'
            color='info'
            sx={{ borderRadius: '0' }}
            onClick={() => setFormValues(initialFormValues)}
          >
            Limpar
          </Button>
          <LoadingButton
            variant='contained'
            type='submit'
            startIcon={<SaveOutlinedIcon />}
            sx={{ borderRadius: '0' }}
            onClick={handleStore}
            loading={formLoading}
          >
            {props.isEditPage ? 'Actualizar' : 'Salvar'}
          </LoadingButton>
        </Stack>
      </BottomPanel>
    </>
  )
}



export default AddProductsPage






