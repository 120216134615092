import { useState } from 'react'
import { SidebarItems } from './sidebar_content'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import Archive from '@mui/icons-material/Archive'
import PaddingIcon from '@mui/icons-material/Padding'
import TimelineIcon from '@mui/icons-material/Timeline'
import MoneyOffIcon from '@mui/icons-material/MoneyOff'
import ListIcon from '@mui/icons-material/List'
import { RouteHelper } from '../../helpers/route_helper'

const SidebarContent = () => {

    const menus = {
        Cadastro: {
            name: 'Cadastro',
            submenus: [
                // {
                //     name: 'Vendedores',
                //     icon: <PersonAddIcon />,
                //     link: RouteHelper.sellers
                // },
                {
                    name: 'Produtos',
                    icon: <Archive />,
                    link: RouteHelper.products
                }
            ]
        },
        Suprimentos: {
            name: 'Suprimentos',
            submenus: [
                {
                    name: 'Gerir estoque',
                    icon: <ListIcon />,
                    link: RouteHelper.checkStock
                }, 
            ]
        },
        Vendas:{
            name:'Vendas',
            submenus:[
                {
                    name: 'Vendas',
                    icon: <PaddingIcon />,
                    link: RouteHelper.sales
                },
                {
                    name: 'PDV',
                    icon: <MoneyOffIcon />,
                    link: RouteHelper.pdv
                }
            ]
        },
        Integracoes:{
            name:'Integrações',
            submenus:[
                {
                    name: 'Integração',
                    icon: <PaddingIcon />,
                    link: RouteHelper.integation
                }
            ]
        }

    }

    const [expanded, setExpanded] = useState<string | false>(false)

    const handleExpandedChange = (isExpanded: boolean, panel: string) => {
        setExpanded(isExpanded ? panel : false)
    }

    return (
        <>
            <SidebarItems name={menus.Cadastro.name} submenus={menus.Cadastro.submenus} expanded={expanded} handleExpandedChange={handleExpandedChange} />
            <SidebarItems name={menus.Suprimentos.name} submenus={menus.Suprimentos.submenus} expanded={expanded} handleExpandedChange={handleExpandedChange} />
            <SidebarItems name={menus.Vendas.name} submenus={menus.Vendas.submenus} expanded={expanded} handleExpandedChange={handleExpandedChange} />
            <SidebarItems name={menus.Integracoes.name} submenus={menus.Integracoes.submenus} expanded={expanded} handleExpandedChange={handleExpandedChange} />
        </>
    )
}

export default SidebarContent