import { CircularProgress, Paper, Stack, Typography } from '@mui/material'
import React from 'react'

const LoadingIndicator = (props: { show: boolean }) => {
    return (
        <Paper
            elevation={24}
            // color='primary'
            sx={{
                position: 'absolute',
                zIndex: '9999',
                top: '50%',
                left: '45%',
                padding: 2,
                backgroundColor:'warning.main',
                display: !props.show ? 'none' : 'block',
            }}>
            <Stack
                direction={'row'}
                alignItems={'center'}
            >
                <CircularProgress color='error' />
                <Typography variant='body2' color={''}>
                    por favor aguarde...
                </Typography>
            </Stack>
        </Paper>
    )
}

export default LoadingIndicator