import axiosInstance from "../../api/api_client"
import { IPostReleasesRequest } from "../models/release"

const getAll = async (productId: number, page?: number) => {
    let response = await axiosInstance.get(`/releases/product/${productId}?page=${page}`)
    return response
}

const getById = async (id: number) => {
    let response = await axiosInstance.get(`/releases/${id}`)
    return response
}

const store = async (data: IPostReleasesRequest) => {
    let response = await axiosInstance.post('/releases', data)
    return response
}


const releasesRepository = {
    getAll,
    getById,
    store,
}

export default releasesRepository