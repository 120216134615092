import { Divider, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { IPostProductErrorsRequest, IPostProductRequest } from '../../shared/services/models/product'
import { ICategoriesResponse } from '../../shared/services/models/category'

interface IComplementaryInfoTabProps extends IPostProductRequest {
    setFormValues: React.Dispatch<React.SetStateAction<IPostProductRequest>>
    errors?: IPostProductErrorsRequest,
    categories?: ICategoriesResponse[],
}

const ComplementaryInfoTab: React.FC<IComplementaryInfoTabProps> = (props) => {
    return (
        <Stack spacing={2} direction={'column'}>
            <Grid container spacing={2}>
                {/* <Grid item xs={12} lg={7}>
                    <TextField select
                        label='Categoria'
                        variant='filled'
                        fullWidth
                        value={props.category_id}
                        onChange={e => props.setFormValues(old => ({ ...old, category_id: 1 }))}
                        helperText={props?.errors?.category_id}
                        error={typeof props?.errors?.category_id !== 'undefined'}
                    >
                        {
                            props?.categories?.map((category, index) => {
                                return (
                                    <MenuItem
                                        key={index}
                                        value={category.id}
                                    >
                                        {category.name}
                                    </MenuItem>
                                )
                            })
                        }
                    </TextField>
                </Grid> */}
                <Grid item xs={12} lg={5}>
                    <TextField
                        label='Márca'
                        variant='filled'
                        fullWidth
                        value={props.brand}
                        onChange={e => props.setFormValues(old => ({ ...old, brand: e.target.value }))}
                        helperText={props?.errors?.brand}
                        error={typeof props?.errors?.brand !== 'undefined'}
                    />
                </Grid>
            </Grid>
            <Divider />
            <Typography variant='h6' >
                Descrição complementar
            </Typography>
            <TextField multiline
                minRows={5}
                label='Descrição complementar'
                variant='filled'
                fullWidth
                value={props.complemtary_description}
                onChange={e => props.setFormValues(old => ({ ...old, complemtary_description: e.target.value }))}
                helperText={props?.errors?.complemtary_description}
                error={typeof props?.errors?.complemtary_description !== 'undefined'}
            />

        </Stack>
    )
}

export default ComplementaryInfoTab