import React from 'react';
import { PieChart, Pie, Tooltip, Cell } from 'recharts';

interface SalesByLocationChartProps {
  data: { name: string; sales: number }[];
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const SalesByLocationChart: React.FC<SalesByLocationChartProps> = ({ data }) => {
  return (
    <PieChart width={400} height={400}>
      <Pie
        data={data}
        cx={200}
        cy={100}
        labelLine={false}
        label={({ name }) => name}
        outerRadius={80}
        fill="#8884d8"
        dataKey="sales"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
    </PieChart>
  );
};

export default SalesByLocationChart;
